<template>
  <div>   
    <v-container grid-list-xl> 
        <v-layout align-center="" justify-center="">
            <v-col sm="12" cols="12">
                <br>
                <v-card > 
                    <mbs-page-tab 
                        :items="PageLinks" /> 
                        
                    <v-card v-if="!PAGE_LOAD" 
                        :height="vsh/2"
                        tile="">
                        <mbs-page-spinner />  
                    </v-card>
                    <div v-else class=" "> 
                        <v-card tile >
                            <v-toolbar color="primary" flat>
                                <v-spacer></v-spacer>  
                                <div v-if="selectedCompany">
                                    <v-btn  
                                        @click="ADD_USER()"
                                        class="mx-2" 
                                        color="secondary" dark >
                                        <v-icon>add</v-icon> Add User
                                    </v-btn> 
                                </div> 
                                <div v-if="selectedCompany">
                                    <v-btn  
                                        :to="'/'+selectedCompany.key+'/settings/roles/register-user'" 
                                        class="mx-2" 
                                        color="secondary" dark >  
                                        Register User
                                    </v-btn> 
                                </div>  
                                <v-menu  bottom left 
                                    v-model="menuTableSet"
                                    :close-on-content-click="false"
                                    :nudge-width="250">
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="mr-5"
                                            dark outlined="" rounded=""
                                            icon
                                            v-on="on" >
                                            <v-icon>mdi-tune</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-title primary-title>
                                            <v-layout >
                                                CUSTOMIZE TABLE
                                                <v-spacer></v-spacer>
                                                <v-btn icon="" @click="menuTableSet=false"><v-icon>close</v-icon></v-btn>
                                            </v-layout>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-list subheader="" dense="">
                                                <div v-for="(item, i) in HEADER_ITEMS" :key="i">
                                                    <v-subheader v-if="item.title">{{item.name}}</v-subheader>
                                                    <v-list-item v-else  @click="MBS.actions.TABLE_HEADER_SELECTOR(HEADER_ITEMS,i)" >
                                                        <v-list-item-icon><v-icon>{{item.show?"mdi-check-box-outline":"mdi-checkbox-blank-outline"}}</v-icon></v-list-item-icon>
                                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                                    </v-list-item> 
                                                </div> 
                                            </v-list> 
                                            <v-layout >
                                                <v-spacer></v-spacer>
                                                <v-btn @click="menuTableSet = false" color="primary">OK</v-btn>
                                            </v-layout>
                                        </v-card-text>
                                        
                                    </v-card> 
                                </v-menu>  
                            </v-toolbar>
                            <v-card-text  v-if="MBS.actions.SIZE(Users)>0">
                                <v-card-title  >
                                    USERS 
                                    <v-spacer></v-spacer>
                                    
                                    <v-text-field
                                        v-model="search"
                                        append-icon="search"
                                        label="Search"
                                        single-line
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table 
                                    :headers="HEADERS('users')"
                                    :items="TAP_USERS"
                                    :search="search" >

                                    <template v-slot:top> 
                                    </template> 

                                    <template v-slot:item.image="{ item }"  > 
                                        <v-avatar v-if="item.photoURL"
                                            size="40"  >
                                            <v-img :src="item.photoURL" lazy-src="https://picsum.photos/10/6?image=3" >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center">
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                            /> 
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </v-avatar> 
                                        <v-avatar v-else
                                            size="40" 
                                            color="secondary" class="white--text" >
                                            <div v-if="item">
                                                {{MBS.actions.TEXT_UP(item.surname?item.surname.slice(0,1):'..')}} 
                                            </div>
                                        </v-avatar>   
                                    </template>
                                    <template v-slot:item.admin="{ item }" > 
                                        <v-btn icon @click="ADD_ADMIN(item)">
                                            <v-icon v-if="item.admin">check_box</v-icon>
                                            <v-icon v-else>check_box_outline_blank</v-icon>
                                        </v-btn>  
                                    </template>

                                    <template v-slot:item.action="{ item,index }" >  
                                        <v-menu 
                                            :set="localItem = item"
                                            tile
                                            v-model="item.menuAction" 
                                            :close-on-content-click="false" 
                                            bottom=""   
                                            eager
                                            :nudge-width="250">
                                            <template v-slot:activator="{ on }">   
                                                <v-btn
                                                    ref="btn"
                                                    id="btn"  
                                                    v-on="on"  
                                                    color="primary"
                                                    icon   >
                                                    <v-icon >mdi-dots-vertical</v-icon>
                                                </v-btn> 
                                            </template>  
                                            <v-card tile 
                                                color="lighten-1" class="">   
                                                <v-card-title  class="headline primary lighten-1 py-1">
                                                    <v-avatar v-if="item.photoURL"
                                                        size="40"  >
                                                        <v-img :src="item.photoURL" />
                                                    </v-avatar> 
                                                    <v-avatar v-else
                                                        size="40" 
                                                        color="secondary" class="white--text" >
                                                        <div v-if="item">
                                                            {{MBS.actions.TEXT_UP(item.surname?item.surname.slice(0,1):'..')}} 
                                                        </div>
                                                    </v-avatar>  
                                                        
                                                    <v-subheader class="white--text font-weight-bold">{{MBS.actions.TEXT_UP11(item.name)}}</v-subheader>
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="item.menuAction=false" dark="" icon=""><v-icon>close</v-icon></v-btn>
                                                </v-card-title> 
                                                <!-- <v-subheader class="b1">MY ACCOUNT</v-subheader> -->
                                                <v-virtual-scroll  
                                                    :bench="3"
                                                    :items="['']"
                                                    height="130"
                                                    item-height="50"  > 
                                                    <template  >  
                                                        <v-list-item  
                                                            @click="EDIT_USER(item)">
                                                            <v-list-item-action >
                                                                <v-btn 
                                                                    color="primary" 
                                                                    icon>
                                                                        <v-icon size="24px">edit</v-icon>
                                                                </v-btn>
                                                            </v-list-item-action>
                                                            <v-list-item-title>Edit</v-list-item-title>
                                                        </v-list-item> 
                                                        <v-list-item  
                                                            @click="DELETE_USER(item)">
                                                            <v-list-item-action >
                                                                <v-btn 
                                                                    color="primary" 
                                                                    icon>
                                                                        <v-icon size="24px">delete</v-icon>
                                                                </v-btn>
                                                            </v-list-item-action>
                                                            <v-list-item-title>Delete</v-list-item-title>
                                                        </v-list-item> 
                                                        
                                                    </template> 
                                                </v-virtual-scroll> 
                                                
                                                <v-divider></v-divider>
                                                    
                                                <v-card-actions class="b1" v-if="false">
                                                    <v-layout class="pa-2  ">
                                                        <v-spacer></v-spacer>
                                                        <div class="mr-2">
                                                            
                                                        </div>
                                                        <v-btn 
                                                            :small = "true"
                                                            rounded outlined
                                                            color="primary"
                                                            @click="item.menuAction=false" >
                                                            <div class="px-2">Close</div> 
                                                        </v-btn> 
                                                    </v-layout> 
                                                </v-card-actions>
                                            </v-card>  
                                        </v-menu>  
                                    </template>
                                    <template v-slot:no-data >
                                        <v-layout align-center="" justify-center="" class="primary lighten-5 pa-5">
                                            <v-icon>warning</v-icon>
                                            Empty
                                        </v-layout>
                                    </template>
                                </v-data-table>   
                            </v-card-text> 
                            <v-sheet v-else  class=""
                                :height="vsh/2"
                                tile="">
                                <v-layout justify-center="" align-center="" fill-height="">
                                    <v-icon class="px-1 pb-1">warning</v-icon>
                                    <div>No User Found</div>
                                </v-layout>
                            </v-sheet> 
                        </v-card>
                    </div>
                </v-card>
            </v-col>  
        </v-layout>
    </v-container> 
    <br>

    <!-- //DIALOGS -->
    <div> 
        <!-- add user -->
        <div v-if="dialog_add_user">
            <v-dialog v-model="dialog_add_user" persistent max-width="800px"> 
                <v-card>
                    <v-card-title class="primary white--text" dark>
                        <span class="headline">ADD USER</span>
                        <v-spacer></v-spacer> 
                        <v-btn icon dark @click="dialog_add_user=false"><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text> 
                        <v-container grid-list-lg>
                            <br>
                            <form id="updateUserForm" @submit.prevent="SUBMIT_USER()">
                                <mbs-input-fields v-if="true"
                                    v-model="input"
                                    :field_items="DATA.FIELDS.add_user"
                                    :select_items="SelectItems"
                                    /> 
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text="" @click="dialog_add_user=false">CANCEL</v-btn>
                                    <v-btn color="primary" :loading="inputLoading" dark="" type="submit">Add User</v-btn>
                                </v-card-actions>
                            </form> 
                        </v-container>
                        <small>Click Update button to save changes</small>
                    </v-card-text> 
                </v-card>
            </v-dialog>  
        </div>
        <!-- edit -->
        <div v-if="dialog_edit_user">
            <v-dialog v-model="dialog_edit_user" persistent max-width="800px"> 
                <v-card>
                    <v-card-title class="primary white--text" dark>
                        <span class="headline">EDIT USER ACCOUNT</span>
                        <v-spacer></v-spacer> 
                        <v-btn icon dark @click="dialog_edit_user=false"><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text> 
                        <v-container grid-list-lg>
                            <br>
                            <form id="updateUserForm" @submit.prevent="UPDATE_USER()">
                                <mbs-input-fields v-if="true"
                                    v-model="input"
                                    :field_items="DATA.FIELDS.edit_user"
                                    :select_items="SelectItems"
                                    /> 
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text="" @click="dialog_edit_user=false">CANCEL</v-btn>
                                <v-btn color="primary" :loading="input.loading" dark="" type="submit">Update User</v-btn>
                                </v-card-actions>
                            </form> 
                        </v-container>
                        <small>Click Update button to save changes</small>
                    </v-card-text> 
                </v-card>
            </v-dialog>  
        </div>
    </div>
  </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    let PAGE_NAME = DATA.ITEMS.USERS.names
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.USERS.names,
                NAME_ITEM:DATA.ITEMS.USERS.name,
                VALUE_ITEMS:DATA.ITEMS.USERS.values,
                VALUE_ITEM:DATA.ITEMS.USERS.value,

                menuTableSet:false,  
                dialog_edit_user:false,
                dialog_add_user:false,
                input:{},
                inputLoading:false,
                search:'',   
                HEADER_ITEMS:[ 
                    {id:1,name:"General",title:true},
                    {id:0,name:"image",show:true},
                    {id:0,name:"No.",value:'no',show:false},  
                    {id:0,name:"name",value:'name',show:true}, 
                    {id:0,name:"surname",value:'surname',show:false}, 
                    {id:0,name:"names",value:'names',show:false}, 
                    {id:0,name:"Phone",value:'phone_number',show:true},   
                    {id:0,name:"User Group",value:'company_user_group_name',show:true},      
                    {id:0,name:"on",value:'user_status.online',show:true},   
                    {id:0,name:"logged",value:'user_status.logged',show:true},   
                    {id:0,name:"status",value:'active_status',show:false},   
                    {id:0,name:"create",value:'created_at_',show:true},   
                    {id:0,name:"update",value:'updated_at_',show:false}, 
                    {id:0,name:"user",show:false},
                    // {id:0,name:"admin",value:'admin',show:true},  
                    {id:0,name:"action",align:"right",show:true}, 
                ],  
                items : [
                    {
                        ion:"statement :",
                        src:'/smart_realtors/imgs/header3.jpg',
                        title:"MANAGE USERS", 
                    }, 
                ], 
                DATA:DATA
            }
        },
        created() {
            try { 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            }catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:this.items,
                    color:'black',
                    height:"250",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            }, 
            PageLinks(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("security",2,true) 
                return thisLinks
            }, 
            Path(){  
                let path = DATA.PATHS.company_users
                return this.MBS.actions.COMPANY_LINK(path,true)
            },  
            selectedCompany(){ 
                return this.$store.getters.getSelectedCompany
            }, 
            AllUsers(){
                let items  =this.$store.getters.getUsers 
                return items
            }, 
            Users(){
                let items  =this.$store.getters.getCompanyUsers
                let list = items?[...items]:null
                if(!list){return null}
                list.sort(this.MBS.actions.dynamicSort('created_at',false))
                return list
            }, 
            UserGroups(){
                return this.$store.getters.getCompanyUserGroups
            }, 
            JoinedUsers(){
                let users = this.Users
                let join = this.MBS.actions.JOIN_USERS(users)
                return join
            }, 
            ACTIVE_USERS(){ 
                let items = this.JoinedUsers  
                if (!items) {return null}
                let filtered_items = items.filter(item=>{
                    return !item.disabled
                })
                return filtered_items
            },
            DISABLED_USERS(){ 
                let items = this.JoinedUsers  
                if (!items) {return null}
                let filtered_items = items.filter(item=>{
                    return item.disabled
                })
                return filtered_items
            },
            TAP_USERS(){
                //todo
                let TAB = 0
                let list = null 
                if (TAB===0) {
                    list = this.ACTIVE_USERS 
                }else if (TAB===1) {
                    list = this.DISABLED_USERS 
                } 
                return list
            },
            SelectItems(){
                let Users = this.AllUsers
                let UserGroups = this.UserGroups 
                return {
                    Users:Users,
                    UserGroups:UserGroups,
                }
            },
        },
        methods: { 
             
            HEADERS(type){
                try {
                    let hds = null
                    if (type === 'users') {
                        hds = this.HEADER_ITEMS
                    }else if (type === 'excel'){
                        hds = this.xHeaders2
                    } 
                    let headers = this.MBS.actions.CREATE_TABLE_HEADER(hds) 
                    return headers
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'HEADERS',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            ADD_USER(){
                try {    
                    this.input = {}
                    this.inputLoading = false
                    this.dialog_add_user = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_USER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            SUBMIT_USER(){
                try {    
                    let p = true
                    if (!p) {
                        let mbs_text = this.MBS.text.edit_item_permission_denied(this.NAME_ITEMS)
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:mbs_text.title,
                           text:mbs_text.text,
                           btnYes:mbs_text.btnYes 
                       }) 
                    } else { 
                        let input = this.input
                        let user = input?input.user:null
                        let key = user?user.key:null
                        let phone_number = user?user.phone_number:null
                        let company_user_group_code = input?input.company_user_group_code:null
                        let selectedCompany = this.selectedCompany
                        let company_key = selectedCompany?selectedCompany.key:null
                        console.log(input,'......');
                        let mbs_text = this.MBS.text.item_action(this.NAME_ITEM,"Add")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=ADD-USER",
                                data:{
                                    phone_number:phone_number,
                                    key:key,
                                    company_user_group_code:company_user_group_code,
                                    company_key:company_key
                                }
                            }
                        })  
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_USER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            EDIT_USER(user){
                try {    
                    let p = true
                    if (!p) {
                        let mbs_text = this.MBS.text.edit_item_permission_denied(this.NAME_ITEMS)
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:mbs_text.title,
                           text:mbs_text.text,
                           btnYes:mbs_text.btnYes 
                       }) 
                    } else {  
                        this.inputCode = null
                        this.input = {}  
                        this.input.company_user_group_code = user.company_user_group?user.company_user_group.code:'' 
                        this.input.key = user.key  
                        this.input.company_key = user.company_key  
                        this.dialog_edit_user= true 

                        try {
                            this.input.password = user.p_uid?this.MBS.crypt.decrypt(user.p_uid):null
                            this.input.p_uid = user.p_uid 
                            this.input.confirm_password = user.p_uid?this.MBS.crypt.decrypt(user.p_uid):null
                        } catch (error) {
                            this.MBS.actions.error({
                                error:{
                                    error:error,
                                    data:user,
                                    type:'Password error',
                                },
                                from:'EDIT_USER',
                                page:PAGE_NAME, 
                            }) 
                        }
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_USER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            DELETE_USER(user){
                try {    
                    let selectedCompany = this.selectedCompany
                    let company_key = selectedCompany?selectedCompany.key:null
                    let p = true
                    if (!p) {
                        let mbs_text = this.MBS.text.edit_item_permission_denied(this.NAME_ITEMS)
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:mbs_text.title,
                           text:mbs_text.text,
                           btnYes:mbs_text.btnYes 
                       }) 
                    } else {  
                        let key = user.key  
                        if (!key) {
                            let mbs_text = this.MBS.text.error_0
                            this.MBS.actions.dialog({
                                show:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes, 
                            })  
                        } else {
                            this.input.check = true
                            let mbs_text = this.MBS.text.delete_item('user')
                            this.MBS.actions.dialog({
                                show:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes,
                                btnNo:mbs_text.btnNo,
                                action:{
                                    code:PAGE_NAME+"=CONFIRM-DELETE-USER",
                                    data:{
                                        ...user,
                                        company_key:company_key
                                    }
                                }
                            })  
                        }
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_USER',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            ADMIN_RIGHT(user){
                try {
                    let admin = user.admin
                    let title = ''
                    let text = ''
                    let btnYes = ''
                    let btnNo = 'Cancel'
                    if (admin) {
                        title = "REMOVE ADMIN RIGHT"
                        text = "Are you sure you want to remove admin right for the user ("+user.phone_number+")?"
                        btnYes = 'REMOVE'
                    } else {
                        title = "ADD ADMIN RIGHT"
                        text = "Are you sure you want to add admin right for the user ("+user.phone_number+")?"
                        btnYes = 'ADD'
                    }
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:title,
                        text:text,
                        btnNo:btnNo,
                        btnYes:btnYes

                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ADMIN',
                        page:PAGE_NAME, 
                    }) 
                }
            },  
            UPDATE_USER(){  
                try {   
                    let key = this.input.key 
                    let company_user_group_code = this.input.company_user_group_code 
                    let company_key = this.input.company_key 
                    if (!company_user_group_code) {
                        let mbs_text = this.MBS.text.add_item_error_not_selected('User Group')
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })   
                    }else { 
                        let data = {
                            key:key, 
                            company_user_group_code:company_user_group_code, 
                            company_key:company_key, 
                        }   
                        let mbs_text = this.MBS.text.update_item('user')
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=UPDATE-USER",
                                data:data
                            }
                        })   
                    }
                } catch (error) { 
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_USER',
                        page:PAGE_NAME, 
                    })
                }
            }, 

            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {    
                        if (action.code ===PAGE_NAME+'=ADD-USER') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.inputLoading = true  
                                    this.$store.dispatch("fi_update",{ 
                                        action:this.VALUE_ITEM,
                                        path:this.Path+action.data.key,
                                        data:{
                                            company_user_group_code: action.data.company_user_group_code
                                        },
                                        us:this.us
                                    }).then(res=>{
                                        return this.$store.dispatch("fi_update",{ 
                                            action:this.VALUE_ITEM,
                                            path:"/USERS/"+action.data.key+"/companies/"+action.data.company_key,
                                            data:{
                                                company_user_group_code: action.data.company_user_group_code
                                            },
                                            us:this.us
                                        })
                                    }).then(res=>{
                                        this.inputLoading = false  
                                        this.dialog_add_user = false  
                                        console.log(res,'res....');
                                    }).catch(error=>{
                                        console.log(error,'errr.....');
                                        this.inputLoading = false  
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            } 
                        } 
                        if (action.code ===PAGE_NAME+'=UPDATE-USER') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.inputLoading = true  
                                    this.$store.dispatch("fi_update",{ 
                                        action:this.VALUE_ITEM,
                                        path:this.Path+action.data.key,
                                        data:{
                                            company_user_group_code: action.data.company_user_group_code
                                        },
                                        us:this.us
                                    }).then(res=>{
                                        return this.$store.dispatch("fi_update",{ 
                                            action:this.VALUE_ITEM,
                                            path:"/USERS/"+action.data.key+"/companies/"+action.data.company_key,
                                            data:{
                                                company_user_group_code: action.data.company_user_group_code
                                            },
                                            us:this.us
                                        })
                                    }).then(res=>{
                                        this.inputLoading = false  
                                        this.dialog_add_user = false  
                                        console.log(res,'res....');
                                    }).catch(error=>{
                                        console.log(error,'errr.....');
                                        this.inputLoading = false  
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            } 
                        } 
                        if (action.code ===PAGE_NAME+'=CONFIRM-DELETE-USER') {
                            if (this.input.check) {  
                                this.input.check = false
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.check = true 
                                        this.MBS.actions.dialogInput({
                                            show:true,
                                            fixed:true,
                                            title:"CONFIRM",
                                            text:"Enter your password to confirm deleting this USER",btnNo:"CANCEL",
                                            btnYes:"CONFIRM",
                                            field:[
                                                {field:"password",label:'Password',type:'password'}
                                            ],
                                            action:{
                                                ...action,
                                                code:PAGE_NAME+"=DELETE-USER",
                                            }
                                        })  
                                    }, this.MBS.data.ACTION_REFRESH_TIME); 
                                } else {
                                    
                                }
                            }  
                        }   
                        if (action.code ===PAGE_NAME+'=DELETE-USER') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(async() => { 
                                        this.input.check = false
                                        this.input.loading = true 
                                        let password = this.MBS.crypt.encrypt(action.password) 
                                        if (password!==this.ud.p_uid) {
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        } else {
                                            this.input.loading = true 
                                            this.$store.dispatch("fi_delete",{ 
                                                action:this.VALUE_ITEM,
                                                path:this.Path+action.data.key  
                                            }).then(res=>{
                                                return this.$store.dispatch("fi_delete",{ 
                                                    action:this.VALUE_ITEM,
                                                    path:"/USERS/"+action.data.key+"/companies/"+action.data.company_key 
                                                })
                                            }).then(res=>{
                                                this.inputLoading = false  
                                                this.dialog_add_user = false  
                                                console.log(res,'res....');
                                            }).catch(error=>{
                                                console.log(error,'errr.....');
                                                this.inputLoading = false  
                                            })
                                        } 
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }  
                        }       
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let logging = ps.find(process=>{
                                return process.name == 'logging'
                            })
                            let getting = ps.find(process=>{
                                return process.name == 'getting/users'
                            })
                            let creating = ps.find(process=>{
                                return process.name == 'creating/user'
                            }) 
                            let editing = ps.find(process=>{
                                return process.name == 'editing/user'
                            }) 
                            let updating = ps.find(process=>{
                                return process.name == 'updating/user'
                            }) 
                            let deleting = ps.find(process=>{
                                return process.name == 'deleting/user'
                            }) 
                             

                            if (getting) {
                                this.PAGE_LOAD =false
                            }else{
                                this.PAGE_LOAD =true
                            }

                            if (logging) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:true,
                                    title:"logging..."
                                }) 
                                this.inputLoading = true
                            }else{ 
                            } 

                            if (creating) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Creating user..."
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  

                            if (editing) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating.."
                                }) 
                                this.inputLoading = true
                            }else{ 
                            } 
                            if (updating) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating.."
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  

                            if (deleting) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:false,
                                    title:"Deleting..."
                                }) 
                            }else{ 
                            }  

                             
                        }else{
                            this.PAGE_LOAD =true
                        }

                        //  RESPONSE 
                        if (res.res==='updating/user/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_ITEM).title,
                                text:this.MBS.text.update_item_success(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.dialog_edit_user = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='updating/user/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_ITEM).title,
                                text:this.MBS.text.update_item_fail(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }
                        
                        if (res.res==='deleting/user/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_ITEM).title,
                                text:this.MBS.text.delete_item_success(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.dialog_edit_user = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/user/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_ITEM).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 

                         

                        
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch: { 
            us(v){ 
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>
 
